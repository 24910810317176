import React, { useState, useEffect, lazy, Suspense, } from 'react';
import { Switch, Redirect, Route, useLocation, useHistory } from 'react-router-dom';
import { getNavItems, getNonNavItems, } from './navs';
import LinearProgress from '@mui/material/LinearProgress';
import UserContext from '../context/UserContext';
import jsAPI from '../helpers/jsAPI';
import common from '../helpers/common';
import PublicRouter from './PublicRouter';
import qs from 'qs';

const MainLayout = lazy(() => import('../layouts/Main'));
const NotFoundView = lazy(() => import('../views/NotFoundView'));

const RemoveTrailingSlash = () => {
  const routerLocation = useLocation();

  if (routerLocation.pathname.match('/.*/$')) {
    return (
      <Redirect
        replace
        to={{
          pathname: routerLocation.pathname.replace(/\/+$/, ''),
          search: routerLocation.search,
        }}
      />
    );
  }
  else {
    return null;
  }
}

const NavigationRouter = (props) => {
  const { client_code, accessKeys, clientAdditionalModules} = props;
  const nonNavItems = [...getNonNavItems()];
  const navItems = [...getNavItems()];

  let origNavItems = [...navItems];
  let routes = [];

  const processRoutes = (route) => {
    // check first if has access in module
    let hasAccess = true;
    if (route.accessKey) {
      hasAccess = route.accessKey === "always_show" || Boolean(common.findInNestedArray(accessKeys, "access_key", null, route.accessKey));
    }
    else if (!route.children && !route.accessKey) {
      hasAccess = false;
    }

    if (hasAccess === false) {
      return;
    }

    if (route.path) {
      routes.push(route);
    }
    else if (route.children && route.children.length > 0) {
      route.children.forEach(child => {
        processRoutes(child);
      })
    }
  }

  origNavItems.forEach(item => {
    processRoutes(item);
  });

  routes = [...nonNavItems, ...routes];

  return (
    <Switch>
      {routes.map((route, index) => {
        let path = route.path;
        let updatedPath = path;

        if (Array.isArray(route.path)) {
          path = [...route.path];
          updatedPath = [];

          path.forEach((p) => {
            updatedPath = [...updatedPath, `/:client_code${p}`];
          });
        }
        else {
          updatedPath = `/:client_code${updatedPath}`;
        }

        return (
          <Route
            key={index}
            exact
            path={updatedPath}
            component={route.component}
          />
        );
      })}
      <Redirect
        exact
        from={`/${client_code}/app`}
        to={`/${client_code}/app/default`}
      />
      <Route
        path="*"
        component={() => <NotFoundView hideHeader hideFooter />}
      />
    </Switch>
  )
}

const AppRouter = () => {
  const location = useLocation();
  const history = useHistory();

  const [user, setUser] = React.useContext(UserContext);

  const [isApp, setIsApp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accessKeys, setAccessKeys] = useState([]);
  const [clientAdditionalModules, setClientAdditionalModules] = useState([]);
  const [checkedSession, setCheckedSession] = useState(false);
  const [isSetRedirectURI, setIsSetRedirectURI] = useState(false);

  useEffect(() => {
    const _isApp = location.pathname.split("/")[2] === "app";
    setIsApp(_isApp);
  }, [location]);

  useEffect(() => {
    if (isApp === true) {
      setLoading(true);

      const getDetails = async () => {
        const responseJSON = await jsAPI.request("/api/users/get/details");
        setAccessKeys([...responseJSON.modules, {access_key: "always_show"}]);
        setClientAdditionalModules([...responseJSON.client_add_modules]);

        setUser(val => {
          return {
            ...val,
            accessKeys: [...responseJSON.modules, {access_key: "always_show"}],
            clientAdditionalModules: [...responseJSON.client_add_modules],
            ...responseJSON.user[0],
          }
        });
      }
      
      const checkSession = async () => {
        const responseJSON = await jsAPI.request("/api/login/check_session");
        const isLoggedIn = responseJSON.success;
        
        setUser(val => {
          return {
            ...val,
            isLoggedIn,
          }
        });

        if (isLoggedIn === true) {
          await getDetails();
        }
        else {
          setIsSetRedirectURI(true)
        }

        setCheckedSession(true);
        setLoading(false);
      }
        checkSession();
    }
    else {
      setLoading(false);
    }
  }, [isApp, setUser]);

  useEffect(() => {
    //redirecting URI
    if (isSetRedirectURI) {
      const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
      const { redirect_uri } = queryString;
      const clientCode = user.client_code || location.pathname.split('/')[1] ;

      if ((location.pathname === `/${clientCode}/login` || location.pathname === "/") && !redirect_uri) {
        history.push(`/${clientCode}/login`);
      }
      else {
        history.push(`/${clientCode}/login?redirect_uri=${encodeURIComponent(location.pathname)}`);
      }
      setIsSetRedirectURI(false)
    }

  }, [isSetRedirectURI, history, location.search, user.client_code, location.pathname])

  useEffect(() => {
    if (user.isLoggedIn === false && checkedSession === true) {
      const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
      const { redirect_uri } = queryString;
      const clientCode = user.client_code;
      if (!redirect_uri) {
        window.location.href = clientCode ? `/${clientCode}/login` : "/login";
      }

    }
  }, [user.isLoggedIn, checkedSession, user.client_code, location.search]);

  return (
    <>
      {loading !== true ? (
        <>
          {isApp === true ? (
            <>
              {user.isLoggedIn === true && (
                <MainLayout>
                  <RemoveTrailingSlash />
                  <Suspense fallback={<LinearProgress />}>
                    <NavigationRouter
                      client_code={user.client_code}
                      accessKeys={accessKeys}
                      clientAdditionalModules={clientAdditionalModules}
                    />
                  </Suspense>
                </MainLayout>
              )}
            </>
          ) : (
            <PublicRouter />
          )}
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}

export default AppRouter;