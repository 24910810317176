import React, { lazy, } from 'react';
import { Switch, Route, } from 'react-router-dom';

const LoginForm = lazy(() => import('../forms/LoginForm'));
const AccountView = lazy(() => import('../views/AccountView'));
const NotFoundView = lazy(() => import('../views/NotFoundView'));
const LandingPage = lazy(() => import('../views/LandingPage'));
const CareerView = lazy(() => import('../views/CareerView'));
const JobsView = lazy(() => import('../views/JobsView').then(module => ({ default: module.JobsView })));

const PublicRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={["/jobs/apply/:client_code/:uuid", "/jobs/:client_code/:uuid", "/jobs/:link"]}
        component={JobsView}
      />
      <Route
        exact
        path={["/careers/:client_code", "/careers/:client_code/filter/:params?"]}
        component={CareerView}
      />
      <Route
        exact
        path={["/:client_code/accounts/activate/:token", "/accounts/forgot_password", "/:client_code/accounts/forgot_password", "/accounts/signup", "/:client_code/accounts/signup"]}
        component={AccountView}
      />
      <Route
        exact
        path={["/login", "/:client_code", "/:client_code/login", "/:client_code/login?" ]}
        component={LoginForm}
      />
      <Route
        exact
        path="/"
        component={LandingPage}
      />
      <Route
        path="*"
        component={NotFoundView}
      />
    </Switch>
  );
}

export default PublicRouter;
